var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      ref: "modal",
      attrs: {
        visible: _vm.visible,
        width: _vm._f("sizeList")(_vm.size),
        "mask-closable": _vm.maskClosable,
        "ok-text": _vm.okText,
        "cancel-text": _vm.cancelText,
        footer: _vm.footer,
        closable: _vm.closable,
        keyboard: _vm.keyboard,
        "confirm-loading": _vm.confirmLoading,
        "destroy-on-close": _vm.destroyOnClose,
        "z-index": _vm.zIndex,
      },
      on: {
        ok: _vm.handleOk,
        cancel: _vm.handleCancel,
        afterClose: _vm.afterClose,
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.type !== ""
            ? _c("a-icon", {
                style: {
                  color: "" + _vm.typeList[_vm.type].color,
                  marginRight: "8px",
                },
                attrs: { type: _vm.typeList[_vm.type].name, theme: "filled" },
              })
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _vm._t("title"),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "modal-content",
          style: Object.assign(
            {},
            { overflow: _vm.overflowAuto ? "auto" : "inherit" },
            _vm.customStyle
          ),
        },
        [_vm._t("default")],
        2
      ),
      _c("template", { slot: "footer" }, [_vm._t("footer")], 2),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }